html, body {
  background-color: #fff;
  color: #636b6f;
  font-family: 'Raleway', sans-serif;
  font-weight: 100;
  height: 100vh;
  margin: 0;
  font-size: 16px;
}

.header-bar {
  background-color: $brand-primary;
  padding: 15px 10px;
  margin-bottom: 30px;
}

.upload-form {
  width: 100%;
  max-width: 300px;
  height: 150px;
  position: relative;

  form {
    height: 100%;
  }

  .submit {
    position: absolute;
    bottom: -20px;
    width: 80%;
    margin: 0 auto;
    left: 0;
    right: 0;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  .btn-group {
    width: 100%;

    .btn{
      width: 50%;
    }
  }

  .control-group {
    width: 100%;
    margin-top: 20px;

    input {
      width: 100%;
      padding: 5px 10px;
      border-radius: 2px;
      border: 1px solid #eee;
      height: 34px;
      font-weight: bold;
    }
  }
}

.csv-submit {
  .controls {
    position: relative;
  }

  i {
    position: absolute;
    top: 11px;
    left: 10px;
  }

  .file-upload-label {
    cursor: pointer;
    width: 100%;
    margin: 0;
    padding: 6px 10px 6px 30px;
    border: 1px solid #eeeeee;
    border-radius: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .file-upload {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
}



.full-height {
  height: 100vh;
}

.flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.position-ref {
  position: relative;
}

.content {
  text-align: center;
}

.title {
  font-size: 36px;
  font-weight: bold;
  color: #fff;

  > a {
    color: #fff;

    &:hover,
    &:focus {
      color: #fff;
      text-decoration: none;
    }
  }
}

.links > a {
  color: #636b6f;
  padding: 0 25px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: .1rem;
  text-decoration: none;
  text-transform: uppercase;
}

.m-b-md {
  margin-bottom: 30px;
}

input.file-upload {
  font-size: 12px;
  display: inline-block !important;
}

.loader {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.6);
  cursor: not-allowed;
  z-index: 10;

  .loading-dots-wrapper {
    width: 100%;
    height: 60px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: center;
  }

  .loading-dot {
    display: inline-block;
    width: 15px;
    height: 15px;
    border: 2px solid white;
    border-radius: 50%;
    margin: 0 2px;
    transform: scale(0);
    animation: fx 1.5s ease infinite;


    &.mid {
      margin-left: 20px;
      margin-right: 20px;
      animation-delay: 0.45s;
    }

    &.right {
      animation-delay: .9s;
    }

    @keyframes fx {
      50% {
        transform: scale(1);
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }

  }
}
#filter-input {
  margin-bottom: 20px;
}

#sites-table {
  tr{
    font-weight: bold;

    &.failed {
      background-color: $alert-danger-bg;
      color: $state-danger-text;
    }
  }
}

.btn.download {
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
}